<template>
  <div class="content-layout">
    <div class="left-layout">
      <a-card title="产品目录" class="tree-card">
        <template slot="extra">
          <a-icon
            v-if="$hasPermissions(['rcs:product_category:create'])"
            type="plus-circle"
            class="product-category-icon"
            @click="handleProductCategoryCreator"
          />
          <a-icon
            v-if="
              languageSelect === $constRepository.language.FILTER_OPTION_ZH_CN.value &&
              $hasPermissions(['rcs:product_category:update'])
            "
            type="edit"
            class="product-category-icon"
            @click="handleProductCategoryEditor"
          />
          <a-icon
            v-if="
              languageSelect === $constRepository.language.FILTER_OPTION_ZH_CN.value &&
              $hasPermissions(['rcs:product_category:delete'])
            "
            type="minus-circle"
            class="product-category-icon"
            @click="onDeleteProductCategory"
          />
        </template>
        <template v-if="$hasPermissions(['rcs:product_category:update'])">
          <a-button type="link" size="small" @click="categorySort = !categorySort" v-if="!categorySort">排序</a-button>
          <a-button type="link" size="small" @click="saveCategorySort" v-else>保存</a-button>
        </template>
        <a-tree
          v-model="checkedKeys"
          @select="onSelect"
          :selected-keys="selectedKeys"
          :draggable="categorySort"
          :checkable="$hasPermissions(['rcs:product_category:update'])"
          default-expand-all
          @check="onCheck"
          @drop="onDrop"
        >
          <a-tree-node v-for="tree in treeData" :key="tree.id" :title="tree.name"></a-tree-node>
        </a-tree>
      </a-card>
    </div>
    <div class="right-layout">
      <a-card>
        <div class="product-category-operations">
          <div class="left-btn">
            <a-button
              v-if="$hasPermissions(['rcs:product_class:create'])"
              type="primary"
              class="add-btn"
              @click="handleClassesCreator()"
              >新增</a-button
            >
            <template v-if="$hasPermissions(['rcs:product_class:update'])">
              <a-button type="primary" class="add-btn" :disabled="!selectedKeys[0]" @click="handleSort" v-if="!isOnSort"
                >排序</a-button
              >
              <a-button type="primary" class="add-btn" @click="saveSort" v-else>保存</a-button>
            </template>
          </div>
          <div class="data-filter">
            <a-select
              v-model="languageSelect"
              placeholder="请选择翻译语言"
              showSearch
              optionFilterProp="children"
              @change="handleLanguage"
              :style="{ width: '8vw' }"
            >
              <a-select-option v-for="(language, key) in languageOptions" :key="key" :value="language.value">
                {{ language.label }}
              </a-select-option>
            </a-select>
            <a-input
              v-model="requestParams.name"
              class="search-keyword"
              clearable
              placeholder="请输入产品大类名称"
              @keyup.enter.native="doSearch"
            ></a-input>
            <a-button class="search-btn" type="primary" @click="doSearch()">搜索</a-button>
          </div>
        </div>
        <a-table
          size="middle"
          :pagination="false"
          :columns="$constRepository.productClass.columns"
          :data-source="tableDate"
          :customRow="isOnSort ? customRow : null"
          class="product-category-table"
          rowKey="id"
        >
          <span slot="action" slot-scope="scope">
            <a-button
              v-if="$hasPermissions(['rcs:product_class:update'])"
              :disabled="languageSelect !== $constRepository.language.FILTER_OPTION_ZH_CN.value"
              class="green-btn"
              ghost
              @click="handleClassesEditor(scope)"
              >编辑</a-button
            >
            <a-divider type="vertical" />
            <a-button
              v-if="
                languageSelect === $constRepository.language.FILTER_OPTION_ZH_CN.value &&
                $hasPermissions(['rcs:product_class:delete'])
              "
              class="red-btn"
              ghost
            >
              <a-popconfirm
                okText="是"
                cancelText="否"
                title="是否删除产品大类?"
                @confirm="() => onDeleteProductClasses(scope)"
              >
                <a href="javascript:;">删除</a>
              </a-popconfirm>
            </a-button>
          </span>
          <template slot="createdTime" slot-scope="scope">{{ scope | parseTime | orNA }}</template>
          <template slot="lastModificationTime" slot-scope="scope">{{ scope | parseTime | orNA }}</template>
        </a-table>
        <div class="pagination" v-if="!isOnSort">
          <a-pagination
            :current="pageInfo.page"
            :show-total="(total) => `共 ${pageInfo.totalElements} 条`"
            show-quick-jumper
            show-size-changer
            :total="pageInfo.totalElements"
            @showSizeChange="onShowSizeChange"
            @change="onChange"
            :pageSizeOptions="['10', '20', '30', '40']"
          />
          <br />
        </div>
      </a-card>
    </div>
    <product-category-form-modal ref="productCategoryFormModal" />
    <product-classes-form-modal v-if="treeData" :treeData="treeData" ref="productClassesFormModal" />
  </div>
</template>

<script>
import lodash from 'lodash';
import clonedeep from 'lodash.clonedeep';
import ProductCategoryFormModal from './components/ProductCategoryFormModal';
import ProductClassesFormModal from './components/ProductClassesFormModal';

export default {
  name: 'ProductCategoryManagement',
  components: { ProductCategoryFormModal, ProductClassesFormModal },
  async activated() {
    await this.listProductCategories();
    this.loadedPageData();
  },
  data() {
    return {
      searchKeyword: '',
      selectedKeys: [],
      languageSelect: this.$constRepository.language.FILTER_OPTION_ZH_CN.value,
      languageOptions: this.$constRepository.language.FILTER_OPTIONS,
      checkedKeys: [],
      requestParams: {},
      tableDate: [],
      timeSearch: [],
      treeData: [],
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
      orderIndex: undefined,
      isOnSort: false,
      categorySort: false,
    };
  },
  methods: {
    customRow(record, index) {
      return {
        // FIXME: draggable： true 不生效还不晓得是什么原因，先使用鼠标移入事件设置目标行的draggable属性
        props: {
          // draggable: 'true'
        },
        style: {
          cursor: 'pointer',
        },
        on: {
          // 鼠标移入
          mouseenter: (event) => {
            // 兼容IE
            const ev = event || window.event;
            ev.target.draggable = true;
          },
          // 开始拖拽
          dragstart: (event) => {
            // 兼容IE
            const ev = event || window.event;
            // 阻止冒泡
            ev.stopPropagation();
            // 得到源目标数据
            this.sourceObj = record;
            this.orderIndex = index;
          },
          // 拖动元素经过的元素
          dragover: (event) => {
            // 兼容 IE
            const ev = event || window.event;
            // 阻止默认行为
            ev.preventDefault();
          },
          // 鼠标松开
          drop: (event) => {
            // 兼容IE
            const ev = event || window.event;
            // 阻止冒泡
            ev.stopPropagation();
            // 得到目标数据
            this.targetObj = record;
            const tableDate = clonedeep(this.tableDate);
            const selectDate = clonedeep(tableDate[this.orderIndex]);
            tableDate.splice(this.orderIndex, 1);
            tableDate.splice(index, 0, selectDate);
            this.tableDate = tableDate;
          },
        },
      };
    },
    onSelect(selectedKeys) {
      this.selectedKeys = selectedKeys;
      this.requestParams.name = '';
      this.loadedPageData();
    },
    async listProductCategories() {
      const params = {
        unpaged: true,
      };
      const headers = {
        'accept-language': this.languageSelect,
      };
      const response = await this.$apiManager.productCategories.getProductCategories(params, headers);
      this.treeData = response.data.elements;
      // this.selectedKeys.push(this.treeData[0].id);
    },
    async listProductClasses() {
      this.isOnSort = false;
      this.categorySort = false;
      const params = {
        productCategoryId: this.selectedKeys[0],
        name: this.requestParams.name,
        page: this.pageInfo.page - 1,
        size: this.pageInfo.size,
      };
      const headers = {
        'accept-language': this.languageSelect,
      };
      const response = await this.$apiManager.productClasses.getProductClasses(params, headers);
      this.tableDate = response.data.elements;
      this.$set(this.pageInfo, 'totalElements', response.data.totalElements);
      this.$set(this.pageInfo, 'totalPages', response.data.totalPages);
      this.$set(this.pageInfo, 'currentPage', response.data.currentPage);
    },
    doSearch() {
      this.pageInfo.page = 1;
      this.loadedPageData();
    },
    async loadedPageData() {
      await this.listProductClasses();
      if (this.pageInfo.currentPage + 1 > this.pageInfo.totalPages && this.pageInfo.currentPage) {
        this.pageInfo.page = 1;
        await this.listProductClasses();
      }
    },
    handleClassesCreator() {
      const createProductClasses = (productClasses) => {
        this.$apiManager.productClasses.createProductClasses(productClasses).then(() => {
          this.$message.success('创建成功');
          this.loadedPageData();
        });
      };
      this.$refs.productClassesFormModal.showCreateDialog(
        { productCategoryId: this.selectedKeys[0] },
        this.treeData,
        createProductClasses,
      );
    },
    handleClassesEditor(record) {
      const updateProductClasses = (productClasses) => {
        this.$apiManager.productClasses.updateProductClasses(productClasses).then(() => {
          this.$message.success('编辑成功');
          this.loadedPageData();
        });
      };
      this.$refs.productClassesFormModal.showUpdateDialog(record, this.treeData, updateProductClasses);
    },
    onDeleteProductClasses(record) {
      this.$apiManager.productClasses.deleteProductClasses(record.id).then(() => {
        this.$message.success('删除成功');
        this.loadedPageData();
      });
    },
    handleProductCategoryCreator() {
      const createProductCategory = (productCategory) => {
        this.$apiManager.productCategories.createProductCategories(productCategory).then(() => {
          this.$message.success('创建成功');
          this.listProductCategories();
        });
      };
      this.$refs.productCategoryFormModal.showCreateDialog(createProductCategory);
    },
    handleProductCategoryEditor() {
      const updateProductCategory = (productCategory) => {
        this.$apiManager.productCategories.updateProductCategories(productCategory).then(() => {
          this.$message.success('修改成功');
          this.listProductCategories();
        });
      };
      if (this.checkedKeys[0]) {
        const checkProductCategory = this.treeData.filter(
          (productCategory) => productCategory.id === this.checkedKeys[0],
        );
        this.$refs.productCategoryFormModal.showUpdateDialog(checkProductCategory[0], updateProductCategory);
      } else {
        this.$message.warning('请勾选需要编辑的产品目录');
      }
    },
    onDeleteProductCategory() {
      if (this.checkedKeys[0]) {
        this.$apiManager.productCategories.deleteProductCategories(this.checkedKeys[0]).then(() => {
          this.$message.success('删除成功');
          this.listProductCategories();
        });
      } else {
        this.$message.warning('请勾选需要删除的产品目录');
      }
    },
    onShowSizeChange(current, pageSize) {
      this.pageInfo.page = 1;
      this.$set(this.pageInfo, 'size', pageSize);
      this.loadedPageData();
    },
    onChange(pageNumber) {
      this.$set(this.pageInfo, 'page', pageNumber);
      this.loadedPageData();
    },
    onCheck() {
      if (this.checkedKeys.length) {
        this.checkedKeys = [lodash.last(this.checkedKeys)];
      }
    },
    async handleLanguage() {
      await this.listProductCategories();
      this.loadedPageData();
    },
    async handleSort() {
      const params = {
        productCategoryId: this.selectedKeys[0],
        name: this.requestParams.name,
        unpaged: true,
      };
      const headers = {
        'accept-language': this.languageSelect,
      };
      const response = await this.$apiManager.productClasses.getProductClasses(params, headers);
      this.tableDate = response.data.elements;
      this.isOnSort = !this.isOnSort;
    },
    async saveSort() {
      this.tableDate.forEach((value, index) => {
        // eslint-disable-next-line no-param-reassign
        value.sort = index;
      });
      this.$apiManager.productClasses.updateSort(this.tableDate).then(() => {
        this.$message.success('排序成功');
        this.doSearch();
      });
      this.isOnSort = !this.isOnSort;
    },
    onDrop(dropResult) {
      const dropKey = dropResult.node.eventKey;
      const dropPos = dropResult.node.pos.split('-');
      const dropPosition = dropResult.dropPosition - Number(dropPos[dropPos.length - 1]);
      const startIndex = this.treeData.map((tree) => tree.id).indexOf(dropResult.dragNodesKeys[0]);
      const treeData = clonedeep(this.treeData);
      const dragObj = clonedeep(treeData[startIndex]);
      treeData.splice(startIndex, 1);
      const index = treeData.map((tree) => tree.id).indexOf(dropKey);
      if (dropPosition === -1) {
        treeData.splice(index, 0, dragObj);
      } else {
        treeData.splice(index + 1, 0, dragObj);
      }
      this.treeData = treeData;
    },
    saveCategorySort() {
      this.treeData.forEach((value, index) => {
        // eslint-disable-next-line no-param-reassign
        value.sort = index;
      });
      this.$apiManager.productCategories.updateSort(this.treeData).then(() => {
        this.$message.success('排序成功');
        this.listProductCategories();
      });
      this.categorySort = !this.categorySort;
    },
  },
};
</script>

<style lang="less" scoped>
.product-category-icon {
  font-size: 1vw;
  margin-right: 0.5vw;
  cursor: pointer;
}
.content-layout {
  height: auto;
  display: flex;
  justify-content: space-between;
  .left-layout {
    margin-left: 1%;
    width: 25%;
    height: auto;
    .tree-card {
      height: 100%;
    }
  }
  .right-layout {
    width: 78%;
    float: right;
    height: auto;
    margin-left: 1%;
  }
}
.green-btn {
  color: green;
  border: none;
}
.red-btn {
  color: red;
  border: none;
}
.product-category-operations {
  display: flex;
  justify-content: space-between;
  .left-btn {
    flex: auto;
  }
  .add-btn {
    margin: 10px 0px;
    margin-left: 3%;
  }
  .data-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    .tip {
      color: #4c4c4c;
    }
    .date-picker-style {
      margin: 0 10px;
    }
    .search-options {
      width: 100px;
    }
    .search-keyword {
      width: 200px;
      margin-left: 2%;
    }
    .search-btn {
      margin: 10px 0px;
      margin-left: 3%;
    }
  }
  .product-category-table {
    margin-top: 2%;
  }
}
.pagination {
  margin-top: 3%;
}
</style>
